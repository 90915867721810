import React, {useState} from 'react';
import {Button, Container, Divider, Stack, TextField, Typography} from '@mui/material';

import httpClient from '../httpClient';

const DeviceSettingsView = () => {
    const [imei, setImei] = useState('');
    const [device, setDevice] = useState();

    const getDevice = async (imei) => {
        // Get device from server
        try {
            const response = await httpClient.get('/device/' + imei);
            const resDevice = response.data?.device;
            if (!resDevice) {
                alert("No device with this imei was found.");
                return;
            }
            setDevice(resDevice);
        } catch (error) {
            console.error('Error getting device:', error);
            alert("There was a problem getting the device with this imei, or the device with this imei does not exist.");
        }
    }

    const updatePostInterval = async (imei, postIntervalMinutes) => {
        // Update post interval
        try {
            const response = await httpClient.post('/device/post-interval/' + imei, {interval: postIntervalMinutes});
            const resDevice = response.data?.device;
            if (!resDevice) {
                alert("No device with this imei was found.");
                return;
            }
            setDevice(resDevice);
        } catch (error) {
            console.error('Error updating post interval:', error);
            alert("There was a problem updating the post interval for this device.");
        }
    }

    const updateLteTimeout = async (imei, lteTimeoutSeconds) => {
        // validate
        if (isNaN(lteTimeoutSeconds) || lteTimeoutSeconds < 60 || lteTimeoutSeconds > 600) {
            alert("Enter a value between 60 and 600 seconds.");
            return;
        }

        // Update LTE timeout
        try {
            const response = await httpClient.post('/device/lte-timeout/' + imei, {lteTimeoutSeconds: lteTimeoutSeconds});
            const resDevice = response.data?.device;
            if (!resDevice) {
                alert("No device with this imei was found.");
                return;
            }
            setDevice(resDevice);
        } catch (error) {
            console.error('Error updating LTE timeout:', error);
            alert("There was a problem updating the LTE timeout for this device.");
        }
    }

    const updateGpsTimeout = async (imei, gpsTimeoutSeconds) => {
        // validate
        if (isNaN(gpsTimeoutSeconds) || gpsTimeoutSeconds < 10 || gpsTimeoutSeconds > 330) {
            alert("Enter a value between 10 and 330 seconds.");
            return;
        }

        // Update GPS timeout
        try {
            const response = await httpClient.post('/device/gps-timeout/' + imei, {gpsTimeoutSeconds: gpsTimeoutSeconds});
            const resDevice = response.data.device;
            if (!resDevice) {
                alert("No device with this imei was found.");
                return;
            }
            setDevice(resDevice);
        } catch (error) {
            console.error('Error updating GPS timeout:', error);
            alert("There was a problem updating the GPS timeout for this device.");
        }
    }

    return (
        <Container>
            {/* Get device */}
            <Typography variant='h5'>Look Up Device</Typography>
            <form onSubmit={(e) => {
                e.preventDefault();
                getDevice(imei)
            }}>
                <Stack width="50%" spacing={2} marginBottom={2}>
                    <TextField
                        id="imei"
                        label="IMEI"
                        value={imei}
                        onChange={(e) => setImei(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => getDevice(imei)}
                    >
                        Get Device
                    </Button>
                </Stack>
            </form>
            <Divider/>
            {/* Show device information */}
            {device && (
                <>
                    <Typography variant='h5'>Device Information</Typography>
                    <p>IMEI: {device.deviceID}</p>
                    <p>Name: {device.name}</p>
                    <p>Organization: {device.organization}</p>
                    <p>Connected Asset: {device.boundAsset?._id}</p>
                    <p>Connected Asset Name: {device.boundAsset?.name}</p>
                    <p>Current CM App Firmware Version: {device.currentCMFirmwareVersion}</p>
                    <p>Current EH Firmware Version: {device.currentEHFirmwareVersion ? "0x" + Number(device.currentEHFirmwareVersion).toString(16).toUpperCase() : ""}</p>

                    {/* Allow configuration */}
                    <Typography variant='h5' gutterBottom>Configuration</Typography>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Stack width="50%" spacing={2}>
                            <TextField
                                id="postIntervalMinutes"
                                name='postIntervalMinutes'
                                label="Post Interval (Minutes)"
                                type="number"
                                value={device.postIntervalMinutes ? device.postIntervalMinutes : ''}
                                onChange={(e) => setDevice({...device, postIntervalMinutes: Number(e.target.value)})}
                            />
                            {!device.postIntervalConfirmed && (
                                <Typography variant='body2' color='error'>New post interval config has not yet been confirmed by the device.</Typography>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(e) => updatePostInterval(imei, device.postIntervalMinutes)}
                            >Update Post Interval</Button>

                            <TextField
                                id="lteTimeoutSeconds"
                                name="lteTimeoutSeconds"
                                label="LTE Timeout (Seconds)"
                                type="number"
                                value={device.lteTimeoutSeconds}
                                onChange={(e) => setDevice({...device, lteTimeoutSeconds: Number(e.target.value)})}
                            />
                            {!device.lteTimeoutConfirmed && (
                                <Typography variant='body2' color='error'>New LTE timeout config has not yet been confirmed by the device.</Typography>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(e) => updateLteTimeout(imei, device.lteTimeoutSeconds)}
                            >Update LTE Timeout</Button>

                            <TextField
                                id="gpsTimeoutSeconds"
                                name="gpsTimeoutSeconds"
                                label="GPS Timeout (Seconds)"
                                type="number"
                                value={device.gpsTimeoutSeconds}
                                onChange={(e) => setDevice({...device, gpsTimeoutSeconds: Number(e.target.value)})}
                            />
                            {!device.gpsTimeoutConfirmed && (
                                <Typography variant='body2' color='error'>New GPS timeout config has not yet been confirmed by the device.</Typography>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(e) => updateGpsTimeout(imei, device.gpsTimeoutSeconds)}
                            >Update GPS Timeout</Button>
                        </Stack>
                    </form>
                </>
            )}

        </Container>
    );
}

export default DeviceSettingsView;