import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage, useFormik } from "formik";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import moment from "moment-timezone";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import {
    Box,
    Button,
    Card,
    FormControl as MuiFormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
} from "@mui/material";

import { Link } from "react-router-dom";

// import DeviceAccessRequestsForm from './DeviceAccessRequestsForm';

import httpClient from "../httpClient";
import { baseURL } from "../config/constants/url";
import { diameterToRevsPerMile, revsPerMileToDiameter } from "../utils";

const ChangeLinkedHubo = (props) => {
    const [devices, setDevices] = useState(null);

    // get devices for dropdown
    useEffect(() => {
        const org = props.user.organization;
        httpClient.get('/organization/devices/' + org._id).then((response) => {
            setDevices(response.data.devices);
        }).catch((reason) => {
            alert("Error getting devices");
            console.log("Error getting devices");
            console.log(reason);
        })
    }, [props.user.organization]);

    const handleSubmit = (values) => {
        if (values.deviceID === "") {
            // unassign device from asset
            httpClient.post(
                '/asset/unassign-device',
                {
                    assetID: props.asset._id,
                    deviceID: props.asset.boundDevice.deviceID
                }
            ).then((response) => {
                alert("Device unassignment successful!");
            }).catch((reason) => {
                alert("Error unassigning device, details in console");
                console.log("Error unassigning device:");
                console.log(reason);
            });
        } else {
            // assign new device to asset
            httpClient.post(
                '/asset/assign-device',
                {
                    assetId: values.assetId,
                    deviceID: values.deviceID
                }
            ).then((response) => {
                alert("Device assignment successful!");
            }).catch((reason) => {
                alert("Error assigning device, details in console");
                console.log("Error assigning device:");
                console.log(reason);
            });
        }
    };

    const formik = useFormik({
        initialValues: {
            assetId: props.asset._id,
            deviceID: props.asset.boundDevice?.deviceID ? props.asset.boundDevice.deviceID : "",
        },
        onSubmit: handleSubmit
    });

    if (devices === null) return (<div>Loading...</div>);

    return (

        <form onSubmit={formik.handleSubmit}>
            <MuiFormControl style={{ width: '100%' }}>
                <InputLabel id="assign-device-label">Linked Hubo</InputLabel>
                <Select
                    fullWidth
                    labelId="assign-device-label"
                    id="assign-device"
                    value={formik.values.deviceID}
                    label="Device ID"
                    onChange={(e) => formik.setFieldValue('deviceID', e.target.value)}
                    onBlur={formik.handleBlur}
                >
                    <MenuItem value={""} key={"default_empty_case"}>None</MenuItem>
                    {devices.map((device) =>
                        <MenuItem id={device.deviceID} value={device.deviceID} key={device.deviceID}>
                            <span style={{ float: 'inline-start', flex: 1 }}>{device.deviceID}</span> &nbsp;
                            <span style={{ float: 'inline-end' }}><small><i>Current Asset:</i> {device.boundAsset !== undefined ? device.boundAsset.name : 'None'}</small></span>
                        </MenuItem>
                    )}
                </Select>
                <Button color="primary" variant="contained" type="submit">Bind selected hubo to this asset</Button>
            </MuiFormControl>
        </form>
    )
}

function ValidateForm(values) {
    let errors = {};

    // if(!values.email)
    //   errors.email = "Email Address Required";

    //check if my values have errors
    return errors;
}

export default class AssetInfoForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            asset: props.asset
        }
    }

    handleSubmit = (values, { props = this.props, setSubmitting }) => {
        // delete values.deviceID;
        delete values.tireDiameter;
        httpClient
            .put(new URL("/asset/" + this.state.asset._id, baseURL), values)
            .then((response) => {
                alert(response.statusText);
                setSubmitting(false);
                return;
            })
            .catch((reason) => {
                alert(reason);
                setSubmitting(false);
                return;
            });
        // Alert probably not needed
        // alert(JSON.stringify(values, null, 2));
    };

    zeroOdometerRequest = () => {
        confirmAlert({
            title: <h4>Confirm Reset</h4>,
            message: (
                <p>
                    Are you sure you wish to reset the odometer reading to zero? Lifetime
                    distance traveled will be retained, but the current value will be
                    lost. <strong>This cannot be undone!</strong>
                </p>
            ),
            buttons: [
                {
                    label: "Cancel",
                    onClick: () => { },
                },
                {
                    label: "Reset to Zero",
                    onClick: () => {
                        httpClient
                            .post(
                                new URL(
                                    "/asset/zero-mileage/" + this.state.asset._id,
                                    baseURL
                                ),
                                {}
                            )
                            .then((response) => {
                                alert("The odometer was reset successfully!");
                                this.setState({ asset: { ...response.data.asset } });
                            })
                            .catch((reason) => {
                                alert(
                                    "There was an error resetting the odometer. Please contact support if the error persists."
                                );
                            });
                    },
                },
            ],
        });
    };

    handleImageChange = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("image", file, "hermes_asset_img" + moment().unix() + "." + file.type.split("/")[1]);
        httpClient.post(
            "/asset/upload-image-by-object-id/" + this.state.asset._id,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        ).then((response) => {
            alert("Image uploaded successfully!");
            this.props.updateAll();
        }).catch((reason) => {
            alert("Error uploading image, details in console");
            console.log("Error uploading image:");
            console.log(reason);
        });
    };

    render() {
        const asset = this.state.asset;
        return (
            <Formik
                enableReinitialize
                initialValues={{
                    _id: asset._id,
                    name: asset.name,
                    description: asset.description,
                    // assetImage: asset.assetImage,
                    assetInfo: {
                        assetNo: asset.assetInfo?.assetNo,
                        assetType: asset.assetInfo?.assetType,
                        vin: asset.assetInfo?.vin,
                    },

                    // maintType: '',
                    // maintMileage: '',
                    // maintTime: '',
                    revsPerMile: asset.revsPerMile,
                    tireDiameter: revsPerMileToDiameter(asset.revsPerMile),
                }}
                validate={ValidateForm}
                onSubmit={this.handleSubmit}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    isSubmitting,
                }) => (
                    <Box>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Paper elevation={2} sx={{ padding: '10px' }}>

                                    <Form>
                                        <h4>Attributes</h4>
                                        <Card variant="outlined" sx={{ padding: '10px' }}>
                                            <Box sx={{ float: 'left' }}>

                                                {asset.imageUrl ? (
                                                    <Box
                                                        src={asset.imageUrl}
                                                        alt="Asset"
                                                        component="img"
                                                        sx={{
                                                            borderRadius: "4px",
                                                            width: "140px",
                                                            height: "130px",
                                                        }}
                                                    />
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            borderRadius: "4px",
                                                            width: "140px",
                                                            height: "130px",
                                                            backgroundColor: "#f0f0f0",
                                                        }}
                                                        alt="Asset"
                                                        src="/splash_screen_image.png"
                                                        component="img"
                                                    />
                                                )}
                                            </Box>
                                            <Box sx={{ float: 'right' }}>
                                                {/* Upload image */}
                                                <h6>Upload New Image</h6>
                                                <label>Select Image...</label>
                                                <input
                                                    type="file"
                                                    id="assetImage"
                                                    name="assetImage"
                                                    accept={".jpg,.jpeg,.png,.gif,.bmp"}
                                                    onChange={this.handleImageChange}
                                                    style={{ width: '100%' }}
                                                />
                                            </Box>
                                        </Card>

                                        <FormGroup style={{ display: 'none' }}>
                                            <FormLabel>Asset ID</FormLabel>
                                            <FormControl
                                                type="text"
                                                name="_id"
                                                placeholder="Asset ID"
                                                disabled
                                                value={values._id}
                                            />
                                            <ErrorMessage name="_id" />
                                        </FormGroup>
                                        <br />
                                        <FormGroup>
                                            <FormLabel>Asset Name</FormLabel>
                                            <FormControl
                                                type="text"
                                                name="name"
                                                placeholder="Asset Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}
                                            />
                                            <ErrorMessage name="name" />
                                        </FormGroup>
                                        <br />
                                        <FormGroup>
                                            <FormLabel>Asset Description</FormLabel>
                                            <FormControl
                                                type="text"
                                                name="description"
                                                placeholder="Asset Description"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.description}
                                            />
                                            <ErrorMessage name="description" />
                                        </FormGroup>
                                        <br />
                                        <FormGroup>
                                            <FormLabel>Asset Number</FormLabel>
                                            <FormControl
                                                type="text"
                                                name="assetNo"
                                                placeholder="Asset Number"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values['assetInfo.assetNo']}
                                            />
                                        </FormGroup>
                                        <br />
                                        <FormGroup>
                                            <FormLabel>Asset Type</FormLabel>
                                            <FormControl
                                                type="text"
                                                name="assetType"
                                                placeholder="Asset Type"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values['assetInfo.assetType']}
                                            />
                                        </FormGroup>
                                        <br />
                                        <FormGroup>
                                            <FormLabel>VIN</FormLabel>
                                            <FormControl
                                                type="text"
                                                name="vin"
                                                placeholder="VIN"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values['assetInfo.vin']}
                                            />
                                        </FormGroup>
                                        <br />
                                        <div>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                disabled={isSubmitting || !httpClient.currentUserIsOrgAdmin()}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Form>
                                </Paper>
                            </Grid>
                            {httpClient.currentUserIsOrgAdmin() ? (

                                <Grid item xs={12} md={6} >
                                    <Paper elevation={2} sx={{ padding: '10px' }}>
                                        <h4>Actions</h4>
                                        <h6>Link Hubodometer to this Asset</h6>
                                        <ChangeLinkedHubo asset={asset} user={this.props.user} />

                                        <br />

                                        <h6>Reset Odometer</h6>
                                        <Box>
                                            <Button
                                                variant="contained"
                                                onClick={this.zeroOdometerRequest}
                                                type="button"
                                            >
                                                Reset Odometer to Zero
                                            </Button>
                                        </Box>

                                        <br />

                                        <h6>Set Tire Size / Revolutions per Mile</h6>
                                        <FormGroup>

                                            <FormLabel>Revolutions per Mile</FormLabel>
                                            <Row>
                                                <Col>
                                                    <FormControl
                                                        type="number"
                                                        name="revsPerMile"
                                                        placeholder="Revolutions per Mile"
                                                        disabled
                                                        value={values.revsPerMile}
                                                    />
                                                    <ErrorMessage name="revsPerMile" />
                                                </Col>
                                                <Col>
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        component={Link}
                                                        to={"/tireconf?asset=" + asset._id + "&nextView=/assetdetail/" + asset._id}
                                                    >
                                                        Change Tire Size...
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </FormGroup>

                                    </Paper>

                                </Grid>
                            ) : null}

                            <br />

                            <br></br>
                            {/* Don't need to ObjectId compare here since they're strings at this point */}
                            {/* {(this.props.user._id === device.user) ? <>
              <h4>Device Access Requests</h4>
              <DeviceAccessRequestsForm user={this.props.user} device={this.device} />
            </> : ''
            } */}


                        </Grid>
                    </Box>
                )
                }
            </Formik>
        );
    }
}
