import React from 'react';
import httpClient from '../httpClient';

import withRouter from '../withRouter';

import Alert from 'react-bootstrap/Alert';

class VerifyEmailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      email: '',
      loading: false,
      errorStatus: false,
      errorMessage: '',
    };
  }

  verifyEmailApiCall = (code, email) => {
    if (!code || !email) {
      console.log("Invalid code or email");
      return;
    }
    const verifyUrl = `/user/verifyEmailByLink/${code}/${email}`;
    httpClient.get(verifyUrl).then((response) => { return response?.data })
      .then((result) => {
        // console.log('result: ', result);
        if (result?.status === 200) {
          // console.log('result: ', result);
          this.setState({
            loading: false,
            errorStatus: false,
            errorMessage: '',
          });
        } else {
          // console.log('error: ', result);
          this.setState({
            loading: false,
            errorStatus: true,
            errorMessage: 'Unable to verify email.',
          });
        }
      })
      .catch((reason) => {
        this.setState({ loading: false });
        console.log('error: ', reason);
      });
  };

  componentDidMount() {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('t');
      const email = queryParams.get('email').trim().replace(' ', '+');
      // console.log('token: ', code + '=' + email);

      if (code && email) {
        this.setState({ code: code, email: email });
        this.verifyEmailApiCall(code, email);
      } else {
        this.setState({
          errorStatus: true,
          errorMessage:
            'Invalid url please resent verification email or contact to out customer support.',
        });
      }
    } catch (error) {
      console.error('error: ' + error.message);
    }
  }

  render() {
    return (
      <div style={styles.body}>
        <div style={styles.container}>
          <div style={styles.header}>
            <h1 style={styles.headerTitle}>
              <p>
                {this.state.errorStatus
                  ? 'Email verification failed'
                  : 'Email verified'}
              </p>
            </h1>
          </div>
          <div style={styles.content}>
            {/* <p style={styles.contentText}>
                {this.state.errorStatus
                  ? this.state.errorMessage
                  : 'Your email has been successfully verified.'}
              </p> */}

            {this.state.errorStatus ? (
              <div>
                <Alert
                  key={'danger'}
                  variant="danger"
                  style={{ marginBottom: 10 }}
                >
                  {this.state.errorMessage}

                </Alert>

                <p>If you are facing trouble verifying your account.Please contact to our support team.</p>
                <p>If you have any questions, please send us an email at <a href="mailto:support@tracmile.com">support@tracmile.com</a>.</p> <br /><br />
              </div>
            ) : (
              <div>
                <Alert
                  key={'success'}
                  variant="success"
                  style={{ marginBottom: 10 }}
                >
                  Your email has been successfully verified.
                </Alert>
                <p>Thank you for verifying your email address.</p>
              </div>
            )}

            <br />

            <a href={'/'} style={styles.button}>
              Log In
            </a>
          </div>
          <div style={styles.footer}>
            <p>© 2024 Tracmile.com. All rights reserved.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(VerifyEmailView);
const styles = {
  body: {
    fontFamily: 'Arial, sans-serif',
    // backgroundColor: '#f4f4f4',
    margin: 0,
    padding: 0
  },
  container: {
    maxWidth: '600px',
    margin: '50px auto',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
    textAlign: 'center'
  },
  header: {
    padding: '20px 0',
    backgroundColor: "#0B62AB",
    color: 'white',
    borderRadius: '10px 10px 0 0'
  },
  headerTitle: {
    margin: 0,
    fontSize: '24px'
  },
  content: {
    padding: '20px'
  },
  contentText: {
    fontSize: '20px',
    color: '#333',
    margin: '20px 0'
  },
  button: {
    display: 'inline-block',
    padding: '15px 25px',
    fontSize: '16px',
    color: 'white',
    backgroundColor: '#0B62AB',
    textDecoration: 'none',
    borderRadius: '5px',
    margin: '20px 0'
  },
  footer: {
    fontSize: '14px',
    color: '#888888',
    marginTop: '30px'
  }
};