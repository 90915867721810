import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import * as Yup from 'yup';
// import FileUpload from "react-mui-fileuploader";

import httpClient from '../httpClient';
import BulkFirmwareDeployment from '../components/BulkFirmwareDeployment';
// import { baseURL } from '../config/constants/url';


const ResetMessageForm = (props) => {

    const validationSchema = Yup.object({
        imei: Yup.string().required('IMEI is required'),
        firmwareType: Yup.string().required('Firmware type is required'),
    });

    const formik = useFormik({
        initialValues: {
            imei: "",
            firmwareType: "cm_afu",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            httpClient.post('/device/clear-fw-update-message/' + values.imei + '/' + values.firmwareType)
                .then((res) => {
                    alert("Successfully cleared firmware update message!");
                }).catch((err) => {
                    alert("There was a problem with the upload: " + err.message);
                });
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Container component={Paper} sx={{marginTop: 6, padding: 2}}>
                <h4>Clear Firmware Update Message</h4>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            id="fwclear_imei"
                            name="imei"
                            label="Enter Device IMEI"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.imei}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            // labelId='fwclear_firmwareType'
                            select
                            id="fwclear_firmwareType"
                            name='firmwareType'
                            value={formik.values.firmwareType}
                            label="Firmware Type"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                            required
                        >
                            <MenuItem value="cm_afu">Cloud Module Application Firmware Update (cm_afu)</MenuItem>
                            <MenuItem value="cm_dmfu">Cloud Module Modem Delta Firmware Update (cm_dmfu)</MenuItem>
                            <MenuItem value="cm_fmfu">Cloud Module Modem Full Firmware Update (cm_fmfu)</MenuItem>
                            <MenuItem value="eh_afu">Energy Harvester Application Firmware Update (eh_afu)</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={formik.handleSubmit}>Clear Firmware Update Message</Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    )
}

const validationSchema = Yup.object({
    versionString: Yup.string().required('Version string is required'),
    imei: Yup.mixed().when('bulkUpdate', {
        is: false,
        then: Yup.string().required('IMEI is required'),
    }),
    selectedDevices: Yup.array().when('bulkUpdate', {
        is: true,
        then: Yup.array().min(1, 'Select at least 1 device for bulk update'),
    }).optional(),
    bulkUpdate: Yup.boolean().optional(),
    firmwareType: Yup.string().required('Firmware type is required'),
    firmwareFile: Yup.mixed().when('firmwareType', {
        is: 'cm_afu',
        then: Yup.mixed().required('Firmware file is required').test(
            'fileName',
            'Ensure correct file is being uploaded (hubo_cm_afu**.bin)',
            (value) => value && value.name.startsWith('hubo_cm_afu') && value.name.endsWith('.bin')
        ),
    }).when('firmwareType', {
        is: 'cm_dmfu',
        then: Yup.mixed().required('Firmware file is required').test(
            'fileName',
            'Ensure correct file is being uploaded (hubo_cm_dmfu**.bin)',
            (value) => value && value.name.startsWith('hubo_cm_dmfu') && value.name.endsWith('.bin')
        ),
    }).when('firmwareType', {
        is: 'cm_fmfu',
        then: Yup.mixed().required('Firmware file is required').test(
            'fileName',
            'Ensure correct file is being uploaded (hubo_cm_fmfu**.cbor)',
            (value) => value && value.name.startsWith('hubo_cm_fmfu') && value.name.endsWith('.cbor')
        ),
    }).when('firmwareType', {
        is: 'eh_afu',
        then: Yup.mixed().required('Firmware file is required').test(
            'fileName',
            'Ensure correct file is being uploaded (hubo_eh_afu**.hex)',
            (value) => value && value.name.startsWith('hubo_eh_afu') && value.name.endsWith('.hex')
        ),
    }),
});

const ManageFirmwareView = () => {
    // const [firmwareFile, setFirmwareFile] = useState();
    const [devices, setDevices] = useState([]);

    useEffect(() => {

        httpClient.get('/device/all-devices').then(response => {
            setDevices(response.data.devices);
        }).catch(error => {
            console.error(error)
            alert('Failed to fetch devices!');
        });

    }, []);

    const formik = useFormik({
        initialValues: {
            updateMethod: 'old',
            versionString: "",
            firmwareType: 'cm_afu',
            imei: "",
            firmwareFile: null,
            bulkUpdate: false,
            selectedDevices: [],
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]);
            }
            let postUrl;
            if (values.imei === undefined) {
                values.imei = "";
            }
            if (values.firmwareType === "cm_afu") {
                postUrl = '/device/upload-cm-firmware/' + values.imei;
            } else if (values.firmwareType === "eh_afu") {
                postUrl = '/device/upload-eh-firmware/' + values.imei;
            } else if (values.firmwareType === "cm_dmfu") {
                postUrl = '/device/upload-cm-mfu/' + values.imei;
            } else if (values.firmwareType === "cm_fmfu") {
                postUrl = '/device/upload-cm-fmfu/' + values.imei;
            }

            // Not needed?
            // if (values.bulkUpdate) {
            //     if (values.selectedDevices.length === 0) {
            //         alert("For bulk firmware update, you must select at least 1 device.");
            //         return;
            //     }
            //     delete formData.imei;
            //     formData.bulkUpdate = true;
            //     formData.selectedDevices = values.selectedDevices;
            // }

            httpClient.post(
                // new URL(postUrl, baseURL),
                postUrl,
                formData
            ).then((res) => {
                alert("Firmware deployment successfully pushed!");
            }).catch((err) => {
                console.error(err);
                alert("There was a problem with the upload: " + err.message);

            });
        },
    });

    const handleDeviceSelectionChange = (devices) => {
        // setSelectedDevices(devices);
        formik.setFieldValue('selectedDevices', devices);
    };

    const handleDeviceSelection = (e, deviceID) => {
        e.preventDefault();
        if (e.target.checked) {
            formik.setFieldValue('selectedDevices', [...formik.values.selectedDevices, deviceID]);
        } else {
            formik.setFieldValue('selectedDevices', formik.values.selectedDevices.filter(id => id !== deviceID));
        }
    }

    const handleSelectAll = () => {
        // TODO: When adding filtering, Select All should only apply to devices
        //       visible with the current filters

        if (devices.length === formik.values.selectedDevices.length) {
            // deselect all
            formik.setFieldValue('selectedDevices', []);
        } else {
            // select all
            const deviceIDList = devices.map(({deviceID}) => deviceID);
            formik.setFieldValue('selectedDevices', deviceIDList);
        }
    }

    const handleFileChange = (e) => {
        // setFirmwareFile(file);
        formik.setFieldValue('firmwareFile', e.currentTarget.files[0]);
    }

    let acceptedFirmwareFiletype;
    switch (formik.values.firmwareType) {
        case 'cm_afu':
            acceptedFirmwareFiletype = '.bin';
            break;
        case 'cm_dmfu':
            acceptedFirmwareFiletype = '.bin';
            break;
        case 'cm_fmfu':
            acceptedFirmwareFiletype = '.cbor';
            break;
        case 'eh_afu':
            acceptedFirmwareFiletype = '.hex';
            break;
        default:
            acceptedFirmwareFiletype = '.bin, .hex, .cbor';
    }
    // if (formik.errors) {

    const handleChangeBulkUpdate = React.useCallback((e) => {
        formik.setFieldValue('bulkUpdate', e.target.checked);
    }, [formik]);

    //     console.log(formik.errors);
    // }
    return (
        <>
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                <Container component={Paper} sx={{padding: 2}}>
                    <Typography variant="h4">Deploy Firmware</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formik.values.bulkUpdate}
                                        />
                                    }
                                    id='bulkUpdate'
                                    name='bulkUpdate'
                                    label="Bulk Update"
                                    onChange={handleChangeBulkUpdate}
                                    // onChange={(e) => alert("BANG " + e.target.checked)}
                                />
                            </FormGroup>
                        </Grid>

                        {formik.values.bulkUpdate ? (
                            <BulkFirmwareDeployment
                                onDeviceSelection={handleDeviceSelection}
                                selectedDevices={formik.values.selectedDevices}
                                devices={devices}
                                onSelectAll={handleSelectAll}
                            />
                        ) : <></>}

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id='updateMethod'>Update Method</InputLabel>
                                <Select
                                    // labelId='updateMethod'
                                    label="Update Method"
                                    id='updateMethod'
                                    name='updateMethod'
                                    value={formik.values.updateMethod}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value='old'>Old Method (Static File Path)</MenuItem>
                                    <MenuItem value='new'>New Method (Dynamic File Path)</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        { formik.values.bulkUpdate ? (<></>) : (
                            <Grid item xs={12}>
                                <TextField
                                    type="text"
                                    id="imei"
                                    name="imei"
                                    label="Enter Device IMEI"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.imei}
                                    required={!formik.values.bulkUpdate}
                                    fullWidth
                                    error={formik.touched.imei && Boolean(formik.errors.imei)}
                                    helperText={formik.touched.imei && formik.errors.imei}
                                />
                            </Grid>
                        ) }
                        <Grid item xs={12}>

                            <TextField
                                // labelId='firmwareType'
                                select
                                id="firmwareType"
                                name='firmwareType'
                                value={formik.values.firmwareType}
                                label="Firmware Type"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                required
                            >
                                <MenuItem value="cm_afu">Cloud Module Application Firmware Update (cm_afu)</MenuItem>
                                <MenuItem value="cm_dmfu">Cloud Module Modem Delta Firmware Update (cm_dmfu)</MenuItem>
                                <MenuItem value="cm_fmfu">Cloud Module Modem Full Firmware Update (cm_fmfu)</MenuItem>
                                <MenuItem value="eh_afu">Energy Harvester Application Firmware Update (eh_afu)</MenuItem>
                            </TextField>

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="text"
                                id="versionString"
                                name="versionString"
                                label='Enter New Firmware Version String (without afw_ prefix, e.g. "3.6.25")'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.versionString}
                                required
                                fullWidth
                                error={formik.touched.versionString && Boolean(formik.errors.versionString)}
                                helperText={formik.touched.versionString && formik.errors.versionString}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* <FileUpload
                            multiFile={false}
                            onFilesChange={handleFilesChange}
                            onContextReady={(context) => {}}
                        /> */}
                            <label>Select Firmware File...</label>
                            <input
                                type="file"
                                id="firmwareFile"
                                name="firmwareFile"
                                accept={acceptedFirmwareFiletype}
                                onChange={handleFileChange}
                                onBlur={formik.handleBlur}
                                style={{ width: '100%' }}
                            />
                            {formik.touched.firmwareFile && formik.errors.firmwareFile ? (
                                <FormHelperText error>{formik.errors.firmwareFile}</FormHelperText>
                            ) : null}
                            <br></br>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" type="submit">Deploy Firmware</Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>

            <ResetMessageForm />
        </>
    )
}

export default ManageFirmwareView;