// User signup form for org signup flow

import React from 'react';

import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import { useFormik } from 'formik';

import * as Yup from 'yup';

const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().required('Required'),
    passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required'),
    fullName: Yup.string().required('Required'),
    mobileNumber: Yup.string().optional("Used to send SMS alerts")
});

const UserSignupForm = (props) => {

    const defaultValues = {
        username: '',
        email: '',
        password: '',
        passwordConfirm: '',
        fullName: '',
        mobileNumber: ''
    };

    const formik = useFormik({
        initialValues: props.user || defaultValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // call the parent's onSubmit function
            props.onSubmit(values);
        }
    });

    const handleBackButton = () => {
        const values = formik.values;
        props.onBackButton(values);
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box component={Container}>
                <Typography variant="h6" marginTop='10px' gutterBottom>User Information</Typography>
                <Grid container spacing={3}>

                    {/* Username removed on 10/17/24 */}
                    {/* <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="username"
                            name="username"
                            label="Username"
                            type="text"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.username && Boolean(formik.errors.username)}
                            helperText={formik.touched.username && formik.errors.username}
                            required
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Email Address"
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="passwordConfirm"
                            name="passwordConfirm"
                            label="Confirm Password"
                            type="password"
                            value={formik.values.passwordConfirm}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
                            helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="fullName"
                            name="fullName"
                            label="Full Name"
                            type="text"
                            value={formik.values.fullName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                            helperText={formik.touched.fullName && formik.errors.fullName}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="mobileNumber"
                            name="mobileNumber"
                            label="Mobile Number"
                            type="text"
                            value={formik.values.mobileNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                            helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                        />
                    </Grid>
                    <Grid item xs={6} md={1}>
                        <Button variant="contained" color="info" type="button" onClick={handleBackButton}>Back</Button>
                    </Grid>
                    <Grid item xs={6} md={1}>
                        <Button variant="contained" color="primary" type="submit">Next</Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    )

}

export default UserSignupForm;
