import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from '@mui/material';
import httpClient from '../httpClient';
import { CheckBox } from '@mui/icons-material';

const BulkFirmwareDeployment = (props) => {
    // const [devices, setDevices] = useState([]);
    const devices = props.devices;
    // const [selectedDevices, setSelectedDevices] = useState([]);
    const selectedDevices = props.selectedDevices;
    const handleDeviceSelection = props.onDeviceSelection;
    const handleSelectAll = props.onSelectAll;

    const onChangeDeviceSelection = props.onChangeDeviceSelection;





    // useEffect(() => {
    //     onChangeDeviceSelection(selectedDevices);
    // }, [selectedDevices, onChangeDeviceSelection]);

    // useEffect(() => {

    //     httpClient.get('/device/all-devices').then(response => {
    //         setDevices(response.data.devices);
    //     }).catch(error => {
    //         console.error(error)
    //         alert('Failed to fetch devices!');
    //     });

    // }, []);


    return (
        <>
            <Box>
                <Typography variant="h6">Bulk Firmware Deployment</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="select-all-checkbox"
                                                id="select-all-checkbox"
                                                checked={(devices.length === selectedDevices.length) && selectedDevices.length > 0}
                                                onChange={handleSelectAll}
                                            />
                                        }
                                    />
                                </TableCell>
                                <TableCell>Device IMEI</TableCell>
                                <TableCell>Device Organization</TableCell>
                                <TableCell>Last Posted Date</TableCell>
                                <TableCell>Last Reported Battery Voltage (V)</TableCell>
                                <TableCell>Current CM App Firmware Version</TableCell>
                                <TableCell>Target CM App Firmware Version</TableCell>
                                <TableCell>Current CM Modem Firmware Version</TableCell>
                                <TableCell>Target CM Modem Firmware Version</TableCell>
                                <TableCell>Current EH Firmware Version</TableCell>
                                <TableCell>Target EH Firmware Version</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {devices.map(device => (
                                <TableRow key={device.deviceID}>
                                     <TableCell>
                                        {/* <Button variant="contained" color="primary">Update</Button> */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name={device.deviceID + '-update'}
                                                    id={device.deviceID + '-update'}
                                                    checked={selectedDevices.includes(device.deviceID)}
                                                    onChange= {(e) => {handleDeviceSelection(e, device.deviceID)}}
                                                />
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>{device.deviceID}</TableCell>
                                    <TableCell>{device.organization?.name}</TableCell>
                                    <TableCell>{device.lastPosted}</TableCell>
                                    <TableCell>{
                                        device.mostRecentData?.devstatus ?
                                            Number(device.mostRecentData.devstatus.split(',')[0] / 100 + 2).toFixed(2) :
                                            'no data'
                                    }</TableCell>
                                    <TableCell>{device.currentCMFirmwareVersion}</TableCell>
                                    <TableCell>{device.targetCMFirmwareVersion}</TableCell>
                                    <TableCell>{
                                        (device.mostRecentData?.devstatus && device.mostRecentData.devstatus.split().length === 6) ?
                                            device.mostRecentData.devstatus.split(',')[4] :
                                            'no data'
                                    }</TableCell>
                                    <TableCell>{device.targetCMFullModemFirmwareVersion}</TableCell>
                                    <TableCell>{device.currentEHFirmwareVersion ? "0x" + Number(device.currentEHFirmwareVersion).toString(16).toUpperCase() : ""}</TableCell>
                                    <TableCell>{device.targetEHFirmwareVersion}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}

export default BulkFirmwareDeployment;