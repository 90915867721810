import React from "react";

import {
  Drawer,
  Toolbar,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";

// icons
import UpgradeIcon from '@mui/icons-material/Upgrade';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PeopleIcon from '@mui/icons-material/People';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import NotificationsIcon from '@material-ui/icons/Notifications';

import { NavLink, useLocation } from "react-router-dom";

const drawerWidth = 240;

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//   },
//   toolbar: {
//     paddingRight: 24, // keep right padding when drawer closed
//   },
//   toolbarIcon: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "flex-end",
//     padding: "0 8px",
//     // ...theme.mixins.toolbar,
//   },
//   appBar: {
//     // zIndex: theme.zIndex.drawer + 1,
//     // transition: theme.transitions.create(["width", "margin"], {
//     //   easing: theme.transitions.easing.sharp,
//     //   duration: theme.transitions.duration.leavingScreen,
//     // }),
//     color: "black",
//     backgroundColor: "white",
//   },
//   // appBarShift: {
//   //   marginLeft: drawerWidth,
//   //   width: `calc(100% - ${drawerWidth}px)`,
//   //   transition: theme.transitions.create(['width', 'margin'], {
//   //     easing: theme.transitions.easing.sharp,
//   //     duration: theme.transitions.duration.enteringScreen,
//   //   }),
//   // },
//   menuButton: {
//     marginRight: 36,
//   },
//   menuButtonHidden: {
//     display: "none",
//   },
//   title: {
//     flexGrow: 1,
//   },
//   drawerPaper: {
//     position: "relative",
//     whiteSpace: "nowrap",
//     width: drawerWidth,
//     // transition: theme.transitions.create("width", {
//     //   easing: theme.transitions.easing.sharp,
//     //   duration: theme.transitions.duration.enteringScreen,
//     // }),
//   },
//   drawerPaperClose: {
//     overflowX: "hidden",
//     // transition: theme.transitions.create("width", {
//     //   easing: theme.transitions.easing.sharp,
//     //   duration: theme.transitions.duration.leavingScreen,
//     // }),
//     // width: theme.spacing(7),
//     // [theme.breakpoints.up("sm")]: {
//     //   width: theme.spacing(9),
//     // },
//   },
//   // appBarSpacer: theme.mixins.toolbar,
//   content: {
//     flexGrow: 1,
//     height: "100vh",
//     overflow: "auto",
//   },
//   container: {
//     paddingTop: theme.spacing(4),
//     paddingBottom: theme.spacing(4),
//   },
//   paper: {
//     padding: theme.spacing(2),
//     display: "flex",
//     overflow: "auto",
//     flexDirection: "column",
//   },
//   fixedHeight: {
//     height: 240,
//   },
// }));

function NavBarDrawer(props) {
  // const classes = useStyles();

  let location = useLocation();
  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const menuItems = [

    {
      img: '/assetsicon.svg',
      text: 'Assets & Hubos',
      url: '/assets'
    },
    {
      img: "/mapicon.svg",
      text: "Map View",
      url: "/mapview",
    },
    {
      img: "/alertsicon.svg",
      text: "Alerts",
      url: "/alerts",
    },
    {
      img: "/linkicon.svg",
      text: "Activate New Hubo",
      url: "/linkhubo",
    },
    /*{
      img: "/profileicon.svg",
      text: "My Profile",
      url: "/profile",
    },*/
  ];

  const adminMenuItems = [
    {
      url: '/managefirmware',
      text: 'Manage Firmware',
      icon: <UpgradeIcon />
    },
    {
      url: '/devicesettings',
      text: 'Device Configuration',
      icon: <SettingsApplicationsIcon />
    },
    {
      url: '/mqttlog',
      text: 'MQTT Message Log',
      icon: <ManageSearchIcon />
    },
    {
      url: '/usermanage',
      text: 'Manage Users',
      icon: <PeopleIcon />
    },
    {
      img: "/devicesicon.svg",
      text: "Old Device List",
      url: "/devices",
    },
  ];


  if (!props.user) {
    return <></>;
  }

  const container = window !== undefined ? () => window.document.body : undefined;

  const drawerContents = (
    <>
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <p
          style={{
            height: "50px",
            padding: "10px",
            marginBottom: "-8px",
            width: "100%",
            backgroundColor: "#1462AC",
            color: "#FFF",
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          MENU
        </p>
        <List>
          {menuItems.map((item) => (
            <ListItem
              button
              selected={item.url === location.pathname}
              key={item.url}
              component={NavLink}
              to={item.url}
              style={{
                borderBottomColor: "#e0e0e0",
                borderBottomWidth: "thin",
                borderBottomStyle: "solid",
              }}
            >
              <ListItemIcon>
                <img src={item.img} alt={item.text}></img>
              </ListItemIcon>
              <ListItemText>{item.text}</ListItemText>
            </ListItem>
          ))}
          {
            (props.user && props.user.admin) ? (
              <>
                <ListSubheader sx={{marginTop: '40px'}}>TracMile Admin Tools</ListSubheader>
                {adminMenuItems.map((item) => (
                  <ListItem
                    button
                    selected={item.url === location.pathname}
                    key={item.url}
                    component={NavLink}
                    to={item.url}
                    style={{
                      borderBottomColor: "#e0e0e0",
                      borderBottomWidth: "thin",
                      borderBottomStyle: "solid",
                    }}
                  >
                    <ListItemIcon>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.text}/>
                  </ListItem>
                ))}
              </>
            ) : (
              <></>
            )
          }
        </List>
      </Box>
    </>
  );

  return (
    <>

      {/* Desktop Style */}
      <Drawer
        variant="permanent"
        open
        // disableScrollLock
        ModalProps={{
          disableScrollLock: true
        }}
        sx={{
          display: {
            xs: "none",
            sm: "block",
          },
          width: drawerWidth,
          flexShrink: 0,
          zIndex: (theme) => theme.zIndex.drawer - 20,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            transition: "box-shadow 300ms cubic- bezier(0.4, 0, 0.2, 1) 0ms",
            boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        {drawerContents}
      </Drawer>

      {/* Mobile Style */}
      <Drawer
        container={container}
        variant="temporary"
        // disableScrollLock
        open={props.open}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
          disableScrollLock: true
        }}
        sx={{
          display: {
            xs: "block",
            sm: "none",
          },
          // width: drawerWidth,
          // flexShrink: 0,
          zIndex: (theme) => theme.zIndex.drawer - 20,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            transition: "box-shadow 300ms cubic- bezier(0.4, 0, 0.2, 1) 0ms",
            boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        {drawerContents}
      </Drawer>
    </>
  );
}

export default NavBarDrawer;
