import React, { useEffect, useState } from 'react';
import {
    Button,
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import httpClient from '../httpClient';

const OrgAssetsList = (props) => {
    let assets = props.assets;
    if (!assets) {
        return (
            <Typography>
                No assets found
            </Typography>
        )
    }
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Asset ID</TableCell>
                        <TableCell>Asset Name</TableCell>
                        <TableCell>Bound Hubo</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {assets.map((asset) => {
                        return (
                            <TableRow key={asset._id}>
                                <TableCell>{asset._id}</TableCell>
                                <TableCell>{asset.name}</TableCell>
                                <TableCell>{asset.boundDevice?.deviceID}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const OrgHubosList = (props) => {
    let devices = props.devices;
    if (!devices) {
        return (
            <Typography>
                No hubos found
            </Typography>
        )
    }
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Hubo ID</TableCell>
                        <TableCell>Hubo Name</TableCell>
                        <TableCell>Bound Asset</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {devices.map((device) => {
                        return (
                            <TableRow key={device._id}>
                                <TableCell>{device.deviceID}</TableCell>
                                <TableCell>{device.name}</TableCell>
                                <TableCell>{device.boundAsset?.name}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const OrgUsersList = (props) => {
    let users = props.users;
    if (!users) {
        return (
            <Typography>
                No users found
            </Typography>
        )
    }
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead sx={{fontWeight: 'bold'}}>
                    <TableRow>
                        <TableCell>Email Address</TableCell>
                        <TableCell>Username</TableCell>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Is Verified?</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.users.map((user) => {
                        return (
                            <TableRow key={user._id}>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>{user.fullName}</TableCell>
                                <TableCell>{user.isVerified ? "Yes" : "No"}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const SubscriptionSettings = (props) => {
    const subscription = props.subscription;
    if (!props.subscriptionIsActive || !subscription || subscription.status !== 'active') {
        return (
            <>
                <Typography>
                    Subscription is not active; you will not be able to access your data. Click the button below to subscribe.
                </Typography>
                <Link to="/prices"><Button variant="contained" color="primary" fullWidth>Subscribe</Button></Link>
            </>
        )
    }

    const last4 = subscription.default_payment_method?.card?.last4;

    return (
        <Container>
            {/* <Typography variant='body1' gutterBottom>Subscription Status: Active</Typography>
            <Typography variant='body1' gutterBottom>Subscription Renewal Date: 2025-04-01</Typography>
            <Typography variant='body1' gutterBottom>Payment Method: Card 1 <i>(Confirmed)</i></Typography>
            <Button variant="contained" color="primary" fullWidth>
                Change Payment Method
            </Button> */}

            <section>
                <hr />
                {/* <h4>
                    <a href={`https://dashboard.stripe.com/test/subscriptions/${subscription.id}`}>
                    {subscription.id}
                    </a>
                </h4> */}

                <p>
                    Status: {subscription.status}
                </p>

                <p>
                    Payment Method: {last4 ? `**** **** **** ${last4}` : "None"}
                </p>

                <p>
                    Subscription Renewal Date: {(new Date(subscription.current_period_end * 1000).toLocaleDateString())}
                </p>

                {/* <Link to={{pathname: '/change-plan', state: {subscription: subscription.id }}}>Change plan</Link><br /> */}
                <Link to={{pathname: '/subscription-cancel', state: {subscription: subscription.id }}}>
                    <Button variant="contained" color="warning" sx={{marginBottom: '10px'}}>Cancel Subscription</Button>
                </Link>
                </section>
        </Container>
    )
}

const OrganizationManageView = (props) => {
    const [org, setOrg] = useState();
    const [orgUsers, setOrgUsers] = useState();
    const [orgAssets, setOrgAssets] = useState();
    const [orgDevices, setOrgDevices] = useState();
    const [subscription, setSubscription] = useState();

    useEffect(() => {
        if (!org) {
            httpClient.get('/organization/current-user').then((response) => {
                setOrg(response.data.organization);
            }).catch((reason) => {
                console.log("Error when getting organizations");
                console.log(reason);
            });
        } else {
            if (orgUsers === undefined) {
                httpClient.get('/organization/users/' + org._id).then((response) => {
                    setOrgUsers(response.data.users);
                }).catch((reason) => {
                    console.log("Error when getting users for organization");
                    console.log(reason);
                });
            }
            if (orgAssets === undefined) {
                httpClient.get('/organization/assets/' + org._id).then((response) => {
                    setOrgAssets(response.data.assets);
                }).catch((reason) => {
                    console.log("Error when getting assets for organization");
                    console.log(reason);
                });
            }
            if (orgDevices === undefined) {
                httpClient.get('/organization/devices/' + org._id).then((response) => {
                    setOrgDevices(response.data.devices);
                }).catch((reason) => {
                    console.log("Error when getting devices for organization");
                    console.log(reason);
                });
            }
            if (subscription === undefined) {
                httpClient.get('/stripe/subscriptions').then((response) => {
                    setSubscription(response.data.subscriptions.data[0]);
                }).catch((reason) => {
                    console.log("Error when getting subscription data");
                    console.log(reason);
                });
            }
        }

    }, [org, orgUsers, orgAssets, orgDevices, subscription]);

    // return (
    //     <div>OrganizationManageView</div>
    // )
    if (!org) {
        return (
            <Typography>
                Loading...
            </Typography>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h4"gutterBottom>Organization Management: {org.name}</Typography>
                {/* <Paper elevation={6}>
                    <Typography variant="h4" align="center">
                        Organization Management
                    </Typography>
                </Paper> */}
            </Grid>
            <Grid item xs={12} sm={6}>
                <Paper elevation={6}>
                    <Typography variant="h5" align="center">
                        Assets
                    </Typography>
                    {/* Add assets management components here */}
                    <OrgAssetsList assets={orgAssets} />
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Paper elevation={6}>
                    <Typography variant="h5" align="center">
                        Hubos
                    </Typography>
                    {/* Add hubos management components here */}
                    <OrgHubosList devices={orgDevices} />
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Paper elevation={6}>
                    <Typography variant="h5" align="center">
                        Users
                    </Typography>
                    {/* Add users management components here */}
                    <OrgUsersList users={orgUsers} />
                    <Button variant="contained" color="primary" component={Link} to="/invite-user" sx={{margin: '10px'}}>Invite User...</Button>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Paper elevation={6}>
                    <Typography variant="h5" align="center">
                        Subscription Settings
                    </Typography>

                    <SubscriptionSettings subscriptionIsActive={org.subscriptionIsActive} subscription={subscription}/>
                </Paper>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
                <Paper elevation={6}>
                    <Typography variant="h5" align="center">
                        Billing Management
                    </Typography>

                </Paper>
            </Grid> */}
            {/* <Grid item xs={12} sm={6}>

            </Grid> */}
            {/* <Grid item xs={12} sm={6}>
                <Button variant="contained" color="secondary" fullWidth>
                    Cancel Subscription
                </Button>
            </Grid> */}
        </Grid>
    );
}

export default OrganizationManageView;